@import url(https://fonts.googleapis.com/css?family=Open+Sans:300i,400,600,700,800);
html,body{
    padding: 0;
    margin: 0;
}
.homelogin {    
    font-size: 14px;
    color: #5b5b5b;
    background: url(../../static/media/medellin.83e22301.jpg) center top no-repeat;
    background-size: cover;
}
.homelogin::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    background: linear-gradient(to bottom, #fff, #00c6fb);
    opacity: 0.7;
}
.block {
    display: block;
}
.color-primary,
.text-primary {
    color: #251972 !important;
}
a.active,
button.active,
a:focus,
button:focus,
a:hover,
button:hover {
    color: #252525;
    outline: none !important;
    text-decoration: none !important;
}
.mggAlert {
    width: 450px;
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 20;
    background: #251971;
    min-height: 150px;
    border-radius: 10px;
    box-shadow: 4px 6px 14px 0px rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 15px;
    height: auto;
    display: none;
}
.mggAlert label{
    display: block;
    text-align: center;
    width: 100%;
    font-size: 22px;
}
.mggAlert button {
    display: block;
    margin: 30px auto 0 auto;
    background:#f29999;
    color: #251971;
    cursor: pointer;
    padding: 8px 13px 8px 13px;
    border: 0;
    font-size: 18px;
    border-radius: 10px;
}
.mggAlert button:hover {
    background: #bf798f;
}
.makeStyles-wrapper-5 {
    flex: none;
    width: 100%;
    margin: 10% auto 0 auto;
    max-width: 400px;
    background-color: #251972;
    padding: 30px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    text-align: center;
    box-shadow: 4px 6px 14px 0px rgba(0, 0, 0, 0.75);
    color: white;
    font-weight: bold;
}

.text-xs-center {
    text-align: center;
}

.pb-xs {
    padding-bottom: 10px;
}

.btngeneral {
    background-color: #d96b9f !Important;
    color: #fff !Important;
    width: 100%;
    border: 0;
    cursor: pointer;
}
.btngeneral:hover {
    background-color: #f5519e !Important;
}

.MuiButton-root {
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: none;
    text-transform: initial;
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.MuiTouchRipple-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}

.pt-1 {
    padding-top: 1rem !important;
}

.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: #e8f0fe;
    box-sizing: content-box;
    -webkit-animation-name: mui-auto-fill-cancel;
            animation-name: mui-auto-fill-cancel;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    margin: 0px 0 20px 0;
    color: black;
}

.btnrecover {
    border: 0;
    background: transparent;
    color: #f5519e;
    font-size: 16px;
    cursor: pointer;
}
.btnrecover:hover {
    color:white
}
.logologin {
    margin: 0 auto 0 20px;
}
.makeStyles-wrapper-5 .logologin {
    margin: 0 auto ;
}
.homeicons svg {
    font-size: 40px;
}
.color-success,
.text-success {
    color: #26dad2;
}
.color-warning,
.text-warning {
    color: #ffb64d;
}
.color-danger,
.text-danger {
    color: #fc6180;
}
.card {
    background: #ffffff none repeat scroll 0 0;
    margin: 15px 0;
    padding: 20px;
    border: 0 solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.card {
    margin-bottom: 30px;
}
.separador{
    clear: both;
    display: block;
    width: 100%;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}
.media-text-right {
    text-align: right;
}
.media-body {
    vertical-align: middle;
}

.dashboard {
    background-color: #fff;
    font-size: 14px;
    padding: 10px;
    color: #5b5b5b;
}

a:hover{
    text-decoration: none;
}
.header {
    position: fixed;
    z-index: 50;
    background: #251972;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
    width: 100%;
}
.header .top-navbar {
    min-height: 50px;
    padding: 0 15px 0 0;
}
.navbar-header{
    text-align: center;
    padding: 10 0 0 20px;
    width: 70%;
}
.navbar-header h3 {
    font-size: 20px;
    color: white;
    margin-top: 20px;
    font-weight: bold;
}
.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
}
.navbar-brand{
    float: left;
}
.header .top-navbar .navbar-nav>.nav-item>.nav-link {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 15px;
    line-height: 40px;
    color: white !Important;
}
.dropdown-toggle::after {
    display:none
}
.notify {
    position: relative;
    top: -22px;
    right: -9px;
}
textarea{
    padding: 10px;
}
.notify .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.notify .point {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: #ef5350;
    position: absolute;
    right: 6px;
    top: -10px;
}
.fix-sidebar .left-sidebar {
    position: fixed;
}
.left-sidebar {
    position: absolute;
    width: 20%;
    height: 100%;
    top: 30px;
    z-index: 20;
    padding-top: 60px;
    background: #fff;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    min-width: 270px;
}
.scroll-sidebar {
    padding-bottom: 60px;
}
.sidebar-nav {
    background: #fff;
    padding: 0;
}
.sidebar-nav ul {
    margin: 0;
    padding: 0;
}
.sidebar-nav ul li.nav-devider {
    height: 1px;
    background: rgba(120, 130, 140, 0.13);
    display: block;
}
.sidebar-nav>ul>li {
    margin-bottom: 5px;
}
.sidebar-nav ul li {
    list-style: none;
}
.sidebar-nav>ul>li>a {
    border-left: 3px solid transparent;
}
.sidebar-nav ul li a {
    color: #607d8b;
    padding: 7px 35px 7px 15px;
    display: block;
    font-size: 14px;
    white-space: nowrap;
}

.sidebar-nav>ul>li>a i {
    width: 27px;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    color: #99abb4;
}
.sidebar-nav ul li a span{
   padding: 0  0 0 10px;
}
.sidebar-nav ul li ul{
    padding: 0;
    margin: 0 0 0 40px;
    display: none;
}
.sidebar-nav ul li ul li {
    list-style-type: disc;
}
.sidebar-nav ul li ul li a {
    padding: 7px 35px 7px 5px;
}
.nameuser span{
    margin-left: 10px;
    font-size: 20px;
}
.salir {
    font-size: 20px !important;
}
.sidebar-nav .has-arrow {
    position: relative;
}
.urlsocial,
.qfaqs,
.rfaqs,
.tfaqs {
   margin: 0 0 20px 0;
}
.qfaqs,
.rfaqs{
  width:80%;
  float: left;
}
.addCatFaqs{
    position: relative;
    float: right;
    background: #251971;
    border: 0;
    color: white;
    padding: 8px;
    margin: 0 10px 10px 0;
    border-radius: 10px;
}
.addCatFaqs:hover {
    color: white;
}
.form-group-faqs{
    max-height: 800px;  
    overflow-y: scroll;
}
.itemfaqs{
   width: 95%;
    margin: 0 auto 30px auto;
    border: 1px solid #847f7f;
    float: left;
    padding: 40px 5px 20px 10px;
    border-radius: 5px;
    position: relative;
    box-shadow: 10px 10px 5px -6px rgba(189,189,189,1);
}
.groupInputFaqs{
    border: 1px solid #251971;
    float: left;
    padding: 10px;
    position: relative;
    margin: 0 1% 10px 0;
    width: 48%;
}
.addCatFaqsmas{
    float: right;
    background: #251971;
    border: 0;
    color: white;
    padding: 8px;
    margin: 0 0 20px;
    border-radius: 10px;
}
.addCatFaqsmas:hover {
    color: white;
}
.addCatFaqsmenos {
    background: red;
    border: 0;
    color: white;
    margin: 5px;
    width: 30px;
    position: absolute;
    right: 0;
    font-size: 20px;
}
#updlat{
    margin: 0 10px 20px 0;
    float: left;
}
#updLng,
#updlng {
    margin: 0 0 20px 0;
    float: right;
}
#updradio{
    margin: 0 10px 10px 0;
    float: left;
}
.MuiTableHead-root th:last-child span{
    display: none;
}
.closeConfirm{
    position: absolute;
    right: 1%;
    top: 1%;
    font-size: 20px;
    color: white;
}
.addCatFaqsmenosppal {
    background: red;
    border: 0;
    color: white;
    margin: 5px;
    width: 30px;
    position: absolute;
    right: 0;
    font-size: 20px;
    top: 0;
}
.sidebar-nav .has-arrow:after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #607d8b;
    right: 1em;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    transform-origin: top;
    top: 47%;
    transition: all 0.3s ease-out;
}
.page-wrapper {
    margin-left: 270px;
    background: #fafafa;
    padding-bottom: 60px;
    padding: 140px 0 50px 0;
    min-height: 700px;
}
.diasruta label {
    display: block;
    float: left;
    margin: 10px;
    width: 20%;
    text-align: center;
    font-size: 12px;
}
.diasruta .control-label{
    width: 100%;
    font-weight: bold;
    text-align: left;
}
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.contBlock{
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #888383;
    margin: 30px 0 30px 0;
}
.bold{
    font-weight: bold;
    color: black;
}
.page-titles {
    background: #fff;
    margin: 0 0 30px;
    padding: 15px 10px;
    z-index: 12;
    box-shadow: 1px 0 5px rgba(0, 0, 0, .1);
    position: fixed;
    width: 80%;
    top: 64px;
    text-align: left;
}
.align-self-center {
    align-self: center !important;
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #fff;
    border-radius: .25rem;
    display: none;
}
.breadcrumb-item {
    display: flex;
}
.breadcrumb-item a{
    color: #3d3b3a;
}
.breadcrumb-item.active {
    color: #6c757d;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem;
}
.formupdate{
    display: none
}
textarea {
    width: 100%;
    min-height: 120px;
}
.btnupdate,
.btnadd {
    background: #251972;
     color: white;
}

.btnupdate:hover,
.btnadd:hover {
    background: #442fcd;
    color: white;
}
.MuiTableRow-root:hover {
    background-color: #535050;
    color: white;
}
.clear{
    display: block;
    clear: both;
}
.mapa {
    background: #dad8d8;
    width: 100%;
    height: 220px;
}
iframe {
    width: 100%;
    min-height: 500px;
    overflow: hidden;
    border: 0;
}
